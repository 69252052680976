<i18n lang="json">
{
  "pt": {
    "instructors": "Professores"
  },
  "en": {
    "instructors": "Instructors"
  }
}
</i18n>

<template>
  <BaseHeader
    :nav-class="[
      $route.path.startsWith('/apply') ? 'lg:flex' : 'md:flex',
      { 'mx-auto': $route.path.includes('/certificate') },
    ]"
    :header-style="{
      lightBorder,
      bgColor: header.bgColor,
      textColor: header.textColor,
      symbolColor: header.symbolColor,
      userNameAltBreakpoint: $route.path.startsWith('/apply'),
      displayUserName: !$route.path.includes('/certificate'),
    }"
    v-bind="{ activeLink, navLinks, userDisplayName, breadcumbs }"
    @logout="logout"
    :link-component="DynamicNuxtLink"
    :client-only-component="ClientOnly"
  >
    <template #logo>
      <NuxtLink
        to="/"
        class="inline-flex items-center gap-2 text-lg font-medium tracking-tight"
      >
        <svg
          width="31"
          height="30"
          viewBox="0 0 31 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="15.5" cy="15" r="15" :fill="aprenderLogoColor" />
        </svg>

        {{ t("instructors") }}
      </NuxtLink>
    </template>
  </BaseHeader>
</template>

<script setup lang="ts">
import { defineComponent, resolveComponent } from "vue";
import { Header as BaseHeader, type NavLink } from "@aprenderdesign/components";
import { COLORS } from "~/assets/constants";
import { type Breadcumb, useHeaderStore } from "~/stores/header";

import type { RouteLocationNormalizedLoaded as Route } from "vue-router";
import { ClientOnly } from "#components";

const DynamicNuxtLink = resolveComponent("NuxtLink");

defineComponent({
  name: "AluneMainHeader",
});

const header = useHeaderStore();

const route = useRoute();
const router = useRouter();

const auth = await useAuth();

const { t } = useI18n();

const navLinks: NavLink[] = [];

const activeLink = ref<NavLink | null>(null);
const aprenderLogoColor = ref(COLORS.redMedium);
const previousRoute = ref<Route | null>(null);

const breadcumbs = computed((): Breadcumb[] => {
  return (
    header.breadcumbsMap[route.path] ||
    (previousRoute.value && header.breadcumbsMap[previousRoute.value.path]) ||
    []
  );
});

const lightBorder = computed(() => !(header.textColor === COLORS.white));

const userDisplayName = computed((): string | undefined => {
  if (!auth.user.value) {
    return undefined;
  }

  const name = auth.user.value.name
    .split(" ")
    .map((t) => t.trim())
    .filter(Boolean);

  const [firstName, lastName] = [
    name[0],
    name.length > 1 ? name[name.length - 1] : "",
  ];

  return firstName + (lastName[0] ? ` ${lastName[0]}.` : "");
});

watch(
  () => route,
  (route, prev) => {
    previousRoute.value = prev;
    setActiveLink(route);
  }
);

onMounted(() => {
  setActiveLink(route);
});

function setActiveLink(route: Route) {
  activeLink.value =
    navLinks.find(
      ({ path }) =>
        path === route.path || (path !== "/" && route.path.includes(path))
    ) || null;
}

async function logout() {
  auth.logout();

  router.push("/login");
}

defineComponent({
  name: "AluneMainHeader",
});
</script>
